export const salesByCategory = [
    {
        count: "25000",
        category: "Total Sales"
   },
    {
        count: "16000",
        category: "Male"
    },
    {
        count: "8000",
        category: "Female"
    },
    {
        count: "16000",
        category: "Kids"
    }
 ]