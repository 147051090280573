export const bestSellers=[
    {
        title: "Bluetooth Speaker",
        price: "$145.00",
        img: "/static/img/product-1.png",
        sales:"19,200",
        rating:"4.8",
        comments:23
    },
    {
        title: "Headphone",
        price: "$200.00",
        img: "/static/img/product-2.png",
        sales:"9,200",
        rating:"4.5",
        comments:10
    },
    {
        title: "AT Jack Radio",
        price: "$40.00",
        img: "/static/img/product-3.png",
        sales:"100",
        rating:"3.4",
        comments:40
    },
    {
        title: "Hi-Fi Speaker",
        price: "$350.00",
        img: "/static/img/product-4.png",
        sales:"1250",
        rating:"4.0",
        comments:15
    }
]