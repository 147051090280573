<template>
	<div>
		<global-sales-chart></global-sales-chart>
	</div>
</template>

<script>
	import GlobalSalesChart from "Components/Charts/GlobalSales";

	export default {
		components: {
			GlobalSalesChart
		},
	};
</script>