export const newArticle= [
   {
      id: 1,
      title:"How to build your own store in less then 10 min with Adminify?",
      authorName: "Nelson Juliyet",
      thumbnail: "/static/img/user-2.jpg",
      btnText:"publish",
      btnColor:"danger",
      sideImg:"/static/img/post-1.jpg"
   },
   {
      id: 2,
      title: "How Adminify is faster than the others?",
      authorName: "Elia Span",
      thumbnail: "/static/img/user-4.jpg",
      btnText:"published",
      btnColor:"primary",
      sideImg:"/static/img/post-2.jpg"
   },
   {
      id: 3,
      title: "Five steps to build your own store with Adminify",
      authorName: "John Doe",
      thumbnail: "/static/img/user-3.jpg",
      btnText:"published",
      btnColor:"primary",
      sideImg:"/static/img/post-3.jpg"
   },
   {
      id: 4,
      title: "How to use Adminify in your existing project?",
      authorName: "Nelson Juliyet",
      thumbnail: "/static/img/user-4.jpg",
      btnText:"publish",
      btnColor:"danger",
      sideImg:"/static/img/post-2.jpg"
   },
   {
      id: 5,
      title:"How to build your own store in less then 10 min with Adminify?",
      authorName: "Nelson Juliyet",
      thumbnail: "/static/img/user-5.jpg",
      btnText:"publish",
      btnColor:"danger",
      sideImg:"/static/img/post-3.jpg"
	},
	{
      id: 6,
      title: "How to use Adminify in your existing project?",
      authorName: "Nelson Juliyet",
      thumbnail: "/static/img/user-4.jpg",
      btnText:"publish",
      btnColor:"danger",
      sideImg:"/static/img/post-2.jpg"
   },
   {
      id: 7,
      title:"How to build your own store in less then 10 min with Adminify?",
      authorName: "Nelson Juliyet",
      thumbnail: "/static/img/user-5.jpg",
      btnText:"publish",
      btnColor:"danger",
      sideImg:"/static/img/post-3.jpg"
   },
]